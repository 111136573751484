<template>
  <div class="cursor-default">
    <div
      class="flex items-center px-2 cursor-pointer"
      title="Delete"
      @click="isOpen = true"
    >
      <slot />
    </div>
    <DefaultModal
      :open="isOpen"
      title="Remove From Gallery"
      @close="isOpen = false"
    >
      <div class="bg-white flex w-full p-8">
        Are you sure you want to remove "{{ galleryUpload.upload.name }}" from this gallery?
      </div>
      <div
        class="flex items-center justify-end gap-2 rounded-b border-t border-gray-200 bg-gray-100 px-8 py-4"
      >
        <div class="btn-primary cursor-pointer" @click="destroy">
          Delete
        </div>
      </div>
    </DefaultModal>
  </div>
</template>
<script setup>
  import { ref } from 'vue';
  import { router } from '@inertiajs/vue3';
  import * as $routes from '@/routes.js'
  import DefaultModal from '../../Shared/DefaultModal.vue';

  const props = defineProps(['galleryUpload'])
  const isOpen = ref(false)

  const destroy = () => router.delete($routes.gallery_upload(props.galleryUpload.id), {
    preserveState: false,
    onSuccess: () => isOpen.value = false
  })
</script>
