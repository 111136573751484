<template>
  <div>
    <div class="cursor-pointer" title="Add Media" @click="isOpen = true">
      <slot />
    </div>
    <DefaultModal
      :open="isOpen"
      title="Add to Playlist"
      @close="isOpen = false"
    >
      <div class="border-b border-secondary-300 mb-4">
        <ul class="flex flex-row -mb-px text-sm font-medium text-center text-secondary-700 w-full">
          <li class="w-1/2">
            <span
              class="w-full inline-flex items-center justify-center p-4 border-b-2 "
              :class="addNew ? 'text-secondary-700 border-secondary-700 cursor-default' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600 cursor-pointer'"
              @click="addNew = true"
            >
              Add New
            </span>
          </li>
          <li class="w-1/2">
            <span
              class="w-full inline-flex items-center justify-center p-4 border-b-2 "
              :class="!addNew ? 'text-secondary-700 border-secondary-700 cursor-default' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600 cursor-pointer'"
              @click="addNew = false"
            >
              From Media Library
            </span>
          </li>
        </ul>
      </div>
      <div v-show="addNew">
        <template v-if="$page.props.auth.user.minutes_used < $page.props.auth.user.total_minutes">
          <div class="gap-6 flex w-full p-8 flex-col">
            <DropZone
              ref="dropZone"
              :form-data="formData"
              :multiple="true"
              :accept="['video/*']"
              :route="$routes.uploads()"
              @processing="dzProcessing = true"
              @success="complete"
              @ready="(count) => readyCount = count"
            />
          </div>
          <div
            v-show="readyCount > 0"
            class="flex items-center justify-between border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
          >
            <LoadingButton
              :loading="dzProcessing"
              class="btn-primary ml-auto"
              @click="submit"
            >
              Upload {{ readyCount }} Media
            </LoadingButton>
          </div>
        </template>
        <template v-else>
          <div class="gap-6 flex w-full p-8 flex-col">
            <StorageUsed class="max-w-64 mx-auto" />
            <p class="mx-auto">
              Storage Limit Exceeded!
            </p>
            <Link :href="$routes.subscriptions()" class="btn-primary inline-block mx-auto">
              Add Storage
            </Link>
          </div>
        </template>
      </div>
      <div v-show="!addNew">
        <div class="gap-6 flex w-full p-8 flex-col">
          <Combobox v-if="!!media.length" v-slot="{open}" v-model="selectedMedia" multiple>
            <div class="relative mb-4">
              <div
                class="relative w-full cursor-default overflow-hidden rounded bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-300 sm:text-sm"
              >
                <ComboboxInput
                  class="form-input"
                  :display-value="() => open ? query : 'Select Media'"
                  :placeholder="open ? query : 'Start typing to search'"
                  :disabled="!open"
                  @change="(event) => query = event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 w-full flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="size-5 text-gray-400 absolute right-2"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <ComboboxOptions class="absolute mt-1 max-h-36 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                <div
                  v-if="filteredMedia.length === 0 && query !== ''"
                  class="relative cursor-default select-none px-4 py-2 text-gray-700"
                >
                  Nothing found for "<i>{{ query }}</i>".
                </div>
                <ComboboxOption
                  v-for="asset in filteredMedia" v-slot="{ active, selected }"
                  :key="asset.id" as="template" :value="asset"
                  :disabled="playlist.upload_ids.includes(asset.id)"
                >
                  <li
                    class="relative cursor-default select-none py-2 pl-10 pr-4 flex flex-row items-center"
                    :class="{
                      'bg-primary-600 text-white': active ,
                      'text-gray-900': !active,
                    }"
                  >
                    <img :alt="asset.name" :src="asset.preview" class="size-8 mr-1">
                    <div v-if="!playlist.upload_ids.includes(asset.id)" class="flex flex-col">
                      <span
                        class="block truncate text-sm max-w-72"
                        :class="{ 'font-medium': selected, 'font-normal': !selected }"
                      >
                        {{ asset.name }}
                      </span>
                      <span
                        class="block truncate text-xs max-w-72"
                        :class="{ 'font-medium': selected, 'font-normal': !selected }"
                      >
                        {{ asset.author.name }}
                      </span>
                    </div>
                    <div v-if="playlist.upload_ids.includes(asset.id)" class="flex flex-col text-green-700">
                      <span
                        class="block truncate text-sm max-w-72"
                        :class="{ 'font-medium': selected, 'font-normal': !selected }"
                      >
                        In Playlist
                      </span>
                      <span
                        class="block truncate text-xs max-w-72"
                        :class="{ 'font-medium': selected, 'font-normal': !selected }"
                      >
                        {{ asset.name }}
                      </span>
                    </div>

                    <span
                      v-if="selected || playlist.upload_ids.includes(asset.id)"
                      class="absolute inset-y-0 left-0 flex items-center pl-3"
                      :class="{ 'text-white': active, 'text-primary-600': !active, 'text-green-700': playlist.upload_ids.includes(asset.id) }"
                    >
                      <CheckIcon class="size-5" aria-hidden="true" />
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
              <ul v-if="selectedMedia.length > 0" class="mt-2 flex flex-col">
                <li class="text-sm font-semibold">
                  Media to Add:
                </li>
                <li v-for="asset in selectedMedia" :key="asset.id" class="py-1 flex flex-row">
                  <img :alt="asset.name" :src="asset.preview" class="size-8 mr-1">
                  <div class="flex flex-col">
                    <span
                      class="block truncate text-sm max-w-72"
                    >
                      {{ asset.name }}
                    </span>
                    <span
                      class="block truncate text-xs max-w-72"
                    >
                      {{ asset.author.name }}
                    </span>
                  </div>
                  <button class="ml-auto">
                    <TrashIcon class="size-4 text-red-700" @click="selectedMedia=selectedMedia.filter(m=>m.id !== asset.id)" />
                  </button>
                </li>
              </ul>
            </div>
          </Combobox>
          <div v-else class="text-center">
            No videos in library yet
          </div>
        </div>
        <div
          v-if="selectedMedia.length"
          class="flex items-center justify-between border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
        >
          <button class="btn-primary ml-auto" @click="submitBulk()">
            Add {{ selectedMedia.length }} To Playlist
          </button>
        </div>
      </div>
    </DefaultModal>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue';
import { Link, router, useForm, usePage } from '@inertiajs/vue3';
import * as $routes from '@/routes.js'
import LoadingButton from '../../Shared/LoadingButton.vue';
import DefaultModal from '../../Shared/DefaultModal.vue';
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption, ComboboxButton,
} from '@headlessui/vue';
import { ChevronUpDownIcon, CheckIcon, TrashIcon } from '@heroicons/vue/24/outline';
import DropZone from '../../Shared/DropZone.vue';
import { DirectUpload } from '@rails/activestorage';
import StorageUsed from '../../Shared/StorageUsed.vue';

const props = defineProps(['playlist'])
const isOpen = ref(false);
const record = ref(false)
const dropZone = ref()
const dzProcessing = ref(false)
const progress = ref(0)
const processing = ref(false)
const addNew = ref(true);
const selectedMedia = ref([]);
const readyCount = ref(0)

const formData = {
  upload: { },
  playlist_id: props.playlist.hashid,
  signed_blob_id: null
}
const form = useForm(formData)
const media = usePage().props.media.filter((a) => a.content_type.includes('video'))
let query = ref('')
let filteredMedia = computed(() =>
  query.value === ''
    ? media
    : media.filter((asset) =>
      asset.name?.toLowerCase().replace(/\s+/g, '')
        .includes(query.value.toLowerCase().replace(/\s+/g, '')) ||
      asset.author.name.toLowerCase().replace(/\s+/g, '')
        .includes(query.value.toLowerCase().replace(/\s+/g, ''))
    )
)

const complete = () => {
  console.log('complete')
  form.reset()
  isOpen.value = false
  dzProcessing.value = false
}
const updateProgress = (event) => {
  progress.value = (event.loaded / event.total) * 100
}
class Upload {
  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => updateProgress(event));
  }
}
const submit = () => {
  if (!record.value) {
    dropZone.value.submit();
  } else {
    processing.value = true
    const upload = new DirectUpload(form.upload.file, '/rails/active_storage/direct_uploads', new Upload);
    upload.create((error, blob) => {
      if (error) {
        alert(error)
      } else {
        form.upload.file = blob
        form.signed_blob_id= blob.signed_id
        form.post($routes.uploads(), {
          onSuccess: () => complete(),
          preserveState: false
        });
      }
    })
  }
}

const submitBulk = () => {
  router.post($routes.playlist_uploads_bulk_create(), { playlist_id: props.playlist.hashid, upload_ids: selectedMedia.value.map(m => m.id)   }, {
    onSuccess: () => complete(),
    preserveState: false
  })
}
</script>
