<template>
  <Layout>
    <Head :title="`${invitable.title || invitable.name} | ${user.first_name} ${user.last_name} | Quick Upload`" />
    <FlashMessages />

    <div class="flex min-h-screen items-center justify-center bg-brand p-6">
      <div class="w-full max-w-md">
        <Logo class="mx-auto block w-full max-w-xs h-24" />
        <div class="mt-8 rounded-t bg-white shadow-xl px-8 pt-6 gap-2 flex flex-col">
          <ol v-if="!invitation.used" class="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center sm:text-base sm:p-4 sm:space-x-4 bg-white justify-center">
            <li class="flex items-center" :class="!started && !media.length ? 'text-primary-600' : ''">
              Welcome
              <svg class="w-3 h-3 ms-2 sm:ms-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 9 4-4-4-4M1 9l4-4-4-4" />
              </svg>
            </li>
            <li class="flex items-center" :class="(started && !media.length) || (media.length && !media[0].processed) ? 'text-primary-600' : ''">
              Media
              <svg class="w-3 h-3 ms-2 sm:ms-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 9 4-4-4-4M1 9l4-4-4-4" />
              </svg>
            </li>
            <li class="flex items-center" :class="media.length && media[0].processed ? 'text-primary-600' : ''">
              Finalize
            </li>
          </ol>
          <p v-else class="text-2xl font-semibold mx-auto text-green-700">
            You did it!
          </p>
        </div>
        <template v-if="!media.length">
          <template v-if="!started">
            <div class="bg-white shadow-xl px-8 pt-4 gap-2 flex flex-col">
              <p>Hi, {{ invitation.first_name }} <span class="text-xs text-secondary hover:text-secondary-700 cursor-pointer" @click="isOpen=true">(not {{ invitation.first_name }}?)</span></p>
              <p class="pb-4">
                {{ user.first_name }} {{ user.last_name }} has invited you to contribute media for
                <strong>{{ invitable.title || invitable.name }}</strong>
              </p>
              <p class="text-xs pb-4">
                By submitting media, you signify your agreement to our
                <a class="text-primary hover:text-primary-600" :href="`${ $env.VITE_BROCHUREWARE_URL }/terms-and-conditions`" target="_blank">terms of use</a> and
                <a class="text-primary hover:text-primary-600" :href="`${ $env.VITE_BROCHUREWARE_URL }/privacy-policy`" target="_blank">privacy policy</a>
              </p>
            </div>
            <div>
              <div
                class="flex items-center justify-between border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
              >
                <button class="btn-primary ml-auto" @click="started=true">
                  Get Started
                </button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="bg-white rounded-b">
              <div class="gap-6 flex w-full p-8 flex-col">
                <div class="text-sm">
                  Use the form below to upload or use your camera to capture your contribution(s).
                </div>
                <DropZone
                  ref="dropZone"
                  :form-data="formData"
                  :multiple="true"
                  :route="$routes.quick_upload()"
                  @processing="dzProcessing = true"
                  @success="dzProcessing = false"
                  @ready="(count) => readyCount = count"
                />
              </div>
              <div
                v-show="readyCount > 0"
                class="flex items-center justify-between border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
              >
                <LoadingButton
                  :loading="dzProcessing"
                  class="btn-primary ml-auto"
                  @click="submit"
                >
                  Upload {{ readyCount }} Media
                </LoadingButton>
              </div>
            </div>
          </template>
        </template>
        <div v-else-if="!invitation.used" class="bg-white rounded-b px-8 py-4 flex flex-col gap-2">
          <p class="text-xs pb-2">
            Please review the attachment<template v-if="media.length > 1">s</template> below.
            You may adjust the title, delete & re-submit, or finalize if everything looks good.
          </p>
          <template v-for="upload in media" :key="upload.id">
            <div class="flex flex-col py-4">
              <div class="flex items-center justify-between">
                <div class="flex gap-2 items-center w-full">
                  <UploadCard :upload="upload" view="quick-upload" />
                  <template v-if="editing">
                    <TextInput
                      v-model="nameForm.upload.name"
                      :errors="nameForm.errors.name"
                      class="w-full"
                    />
                    <CheckCircleIcon class="size-8 text-green-700 cursor-pointer" @click="updateName" />
                  </template>
                  <template v-else>
                    <div class="overflow-hidden text-ellipsis text-nowrap max-w-32 md:max-w-56 mr-2">
                      {{ nameForm.upload.name }}
                    </div>
                    <PencilSquareIcon class="size-5 text-primary hover:text-primary-600 cursor-pointer" @click="editing = true" />
                  </template>
                </div>
                <div class="flex justify-around sm:justify-end">
                  <QuickDestroy :upload :invitation>
                    <TrashIcon class="size-6 text-red-700 hover:text-red-600" />
                  </QuickDestroy>
                </div>
              </div>
            </div>
          </template>
          <div class="w-full flex justify-center gap-2">
            <button class="btn-primary" @click="finalize">
              Looks Good!
            </button>
            <button class="btn-secondary" @click="addMore = true">
              Add More
            </button>
            <DefaultModal
              :open="addMore"
              title="Contribute More"
              @close="addMore = false"
            >
              <div class="bg-white rounded-b">
                <div class="gap-6 flex w-full p-8 flex-col">
                  <DropZone
                    ref="dropZone"
                    :form-data="formData"
                    :multiple="true"
                    :route="$routes.quick_upload()"
                    @processing="dzProcessing = true"
                    @success="dzProcessing = false"
                    @ready="(count) => readyCount = count"
                  />
                </div>
                <div
                  v-show="readyCount > 0"
                  class="flex items-center justify-between border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
                >
                  <LoadingButton
                    :loading="dzProcessing"
                    class="btn-primary ml-auto"
                    @click="submit"
                  >
                    Upload {{ readyCount }} Media
                  </LoadingButton>
                </div>
              </div>
            </DefaultModal>
          </div>
        </div>
        <div v-else class="bg-white rounded-b px-8 py-4 text-xs">
          <div class="text-lg mb-4">
            <div>{{ invitation.first_name }}, <span class="text-xs text-secondary hover:text-secondary-700 cursor-pointer" @click="isOpen=true">(not {{ invitation.first_name }}?)</span></div> we deeply appreciate your contribution to <strong>{{ invitable.title || invitable.name }}</strong>!
          </div>
          If you'd like to create your own group video tribute to celebrate a loved one or special occasion, start today at <Link :href="$routes.onboard_start()" class="inline text-secondary">
            Heartfelt Frames
          </Link>
        </div>
      </div>
    </div>
    <DefaultModal
      :open="isOpen"
      title="Request Invitation"
      @close="isOpen = false"
    >
      <div class="p-8 flex flex-col gap-6">
        <p class="font-semibold">
          This upload link was created specifically for {{ invitation.first_name }}.
        </p>
        <p>If you would like to contribute to <strong>{{ invitable.title || invitable.name }}</strong>, please request your own invitation from {{ user.first_name }} {{ user.last_name }}:</p>
        <form @submit.prevent="submitRequest">
          <div class="gap-6 flex flex-col w-full " :class="{'flex-wrap' : quickAdd}">
            <TextInput
              v-model="requestInviteForm.invitation.first_name"
              :errors="requestInviteForm.errors.first_name"
              label="Your First Name"
              class="w-full"
            />

            <TextInput
              v-model="requestInviteForm.invitation.last_name"
              :errors="requestInviteForm.errors.last_name"
              label="Your Last Name"
              class="w-full"
            />

            <TextInput
              v-model="requestInviteForm.invitation.email"
              type="email"
              :errors="requestInviteForm.errors.email"
              label="Your Email"
              class="w-full"
            />

            <div>
              <LoadingButton :loading="requestInviteForm.processing" class="btn-primary mx-auto">
                Submit Request
              </LoadingButton>
            </div>
          </div>
          <slot />
        </form>
      </div>
    </DefaultModal>
  </Layout>
</template>

<script setup>
import { Head, router, useForm, Link } from '@inertiajs/vue3';
import Layout from '@/Layouts/Minimal.vue';
import FlashMessages from '@/Shared/FlashMessages.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import Logo from '@/Shared/Logo.vue';
import * as $routes from '@/routes';
import { onUnmounted, ref, watch } from 'vue';
import UploadCard from '../Uploads/Upload.vue';
import DropZone from '../../Shared/DropZone.vue';
import QuickDestroy from '../Uploads/QuickDestroy.vue';
import { TrashIcon, CheckCircleIcon, PencilSquareIcon } from '@heroicons/vue/24/outline';
import TextInput from '../../Shared/TextInput.vue';
import DefaultModal from '../../Shared/DefaultModal.vue';

const props = defineProps(['invitation', 'user', 'invitable', 'media'])
const dropZone = ref()
const dzProcessing = ref(false)
const started = ref(false)
const editing = ref(false)
const isOpen = ref(false)
const addMore = ref(false)
const readyCount = ref(0)

const formData = {
  upload: { },
  invitation_id: props.invitation.id,
  signed_blob_id: null
}

const submit = () => {
  dropZone.value.submit();
}

const finalize = () => {
  if (nameForm.isDirty) {
    nameForm.put($routes.quick_rename(props.invitation.code), { preserveState: true, preserveScroll: true, onSuccess: () => router.put($routes.finalize_contribution(props.invitation.code)) })
  } else {
    router.put($routes.finalize_contribution(props.invitation.code))
  }
}

const nameForm = useForm({
  upload: {
    name: props.media[0]?.name
  }
})

watch([props.media], () => {
  if (props.media.length) {
    nameForm.upload.name = props.media[0].name
  }
});

/*
//Disable refresh for now -- need to check all media, not just first.  hiding transcoding from contributors for now
let timer = setInterval(() => {
  if (props.media.length && !props.media[0].processed && props.media[0]?.content_type === 'video') {
    router.reload({
      preserveState: true, preserveScroll: true
    })
  }
}, 1000);

onUnmounted(() => {
  clearInterval(timer);
});
*/

const updateName = () => {
  editing.value = false
  nameForm.put($routes.quick_rename(props.invitation.code), { preserveState: true, preserveScroll: true })
}

const requestInviteForm = useForm({
  invitation: {
    first_name: '',
    last_name: '',
    email: '',
    invitable_id: props.invitation.invitable_id,
    invitable_type: props.invitation.invitable_type
  }
})

const submitRequest = () =>  requestInviteForm.post($routes.request_invite(props.invitation.code))
</script>
