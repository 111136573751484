<template>
  <div>
    <button class="flex items-center px-2 text-primary hover:text-primary-700" title="Allocate Storage" @click="isOpen=true">
      <slot />
    </button>
    <DefaultModal
      :open="isOpen"
      :title="`Assign Representation to ${user.name}`"
      @close="isOpen = false"
    >
      <div class="gap-6 flex flex-wrap w-full p-8">
        Manage Reps
      </div>
    </DefaultModal>
  </div>
</template>

<script setup>
import DefaultModal from '../../Shared/DefaultModal.vue';
import { ref } from 'vue';

defineProps(['user'])
const isOpen = ref(false)
</script>
