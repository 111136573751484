<template>
  <div class="max-w-3xl px-2 mx-auto">
    <img v-if="imageSrc" :src="imageSrc" class="mt-8 mb-4" alt="User Photo">
    <WebCamUI
      v-show="!imageSrc"
      :fullscreen-button="{
        display: false,
      }"
      class="mt-8 mb-4 [&>video]:max-h-[75vh]"
      @photo-taken="photoTaken"
    />
    <div v-if="imageSrc" class="mb-8 text-center">
      <button type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="imageSrc = null">
        Retry
      </button>
      <button type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="emit('success', blob)">
        Submit
      </button>
    </div>
  </div>
</template>

<script setup>
import { WebCamUI } from "vue-camera-lib";
import { ref } from 'vue';

const imageSrc = ref(null)
const blob = ref()
const emit = defineEmits(['success'])

const photoTaken = (data) => {
  imageSrc.value = data.image_data_url
  blob.value = data.blob
}
</script>
