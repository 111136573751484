<template>
  <section>
    <div class="px-4 mx-auto max-w-screen-xl lg:px-6">
      <div class="mx-auto max-w-screen-md text-center">
        <template v-if="$env.VITE_APP_ID==='hff'">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
            Create and share group video tributes
          </h2>
          <p class="font-light text-gray-500 sm:text-xl">
            We offer a variety of plans to best meet your needs, whether you're looking to create a single tribute or collaborate with your entire corporate team.
          </p>
        </template>
        <template v-if="$env.VITE_APP_ID==='rt' || $env.VITE_APP_ID==='esr'">
          <h2 v-if="selectedPlan.trial_days > 0" class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
            {{ selectedPlan.trial_days }}-day free trial offer!
          </h2>
          <p class="font-light text-gray-500 sm:text-xl">
            {{ selectedPlan.name }}                               <DollarAmount :amount="frequency === 'monthly' ? selectedPlan.monthly_price : selectedPlan.annual_price / 12" :class="{'line-through text-3xl': !!$page.props.customer?.discount?.coupon}" />
            <span v-if="!!$page.props.customer?.discount?.coupon" class="ml-2 text-green-700">
                        <DollarAmount
                          :amount="frequency === 'monthly' ?
                            selectedPlan.monthly_price - Math.floor(selectedPlan.monthly_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 :
                            (selectedPlan.annual_price/12) - Math.floor(selectedPlan.annual_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 / 12"
                        />
                      </span>
            <span class="text-gray-500">/month</span>
          </p>
          <div v-if="frequency === 'annual'" class="text-gray-500 text-xs mt-2">
            billed annually
          </div>
        </template>
      </div>
      <div class="flex justify-center py-4">
        <Toggle
          v-model="frequency" :classes="{
            container: 'inline-block rounded-full outline-none focus:ring focus:ring-primary-700 focus:ring-opacity-30',
            toggle: 'flex w-28 h-8 rounded-full relative cursor-pointer transition items-center box-content border-2 text-xs leading-none font-semibold',
            toggleOn: 'bg-primary-700 border-primary-700 justify-start text-white',
            toggleOff: 'bg-primary-700 border-primary-700 justify-end text-white',
            toggleOnDisabled: 'bg-gray-300 border-gray-300 justify-start text-gray-400 cursor-not-allowed',
            toggleOffDisabled: 'bg-gray-200 border-gray-200 justify-end text-gray-400 cursor-not-allowed',
            handle: 'inline-block bg-white w-8 h-8 top-0 rounded-full absolute transition-all',
            handleOn: 'left-full transform -translate-x-full',
            handleOff: 'left-0',
            handleOnDisabled: 'bg-gray-100 left-full transform -translate-x-full',
            handleOffDisabled: 'bg-gray-100 left-0',
            label: 'text-center w-fit border-box whitespace-nowrap select-none pl-6 pr-4',
          }"
          true-value="annual"
          false-value="monthly"
          on-label="Yearly"
          off-label="Monthly"
          @change="(v) => changeFrequency(v)"
        />
      </div>

      <template v-if="$env.VITE_APP_ID==='hff'">
        <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
          <template
            v-for="plan in $page.props.plans"
            :key="plan.id"
          >
            <div
              class="flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg shadow xl:p-8"
              :class="selectedPlan.id === plan.id ? 'bg-secondary-50 border border-secondary-700' : 'bg-white'"
            >
              <h3 class="mb-4 text-2xl font-semibold">
                {{ plan.name }}
              </h3>
              <p class="font-light text-gray-500 sm:text-lg">
                {{ plan.description }}
              </p>
              <div class="flex flex-col my-8">
                <div class="flex justify-center items-baseline">
                            <span class=" text-5xl font-extrabold">
                              <DollarAmount :amount="frequency === 'monthly' ? plan.monthly_price : plan.annual_price / 12" :class="{'line-through text-3xl': !!$page.props.customer?.discount?.coupon}" />
                              <span v-if="!!$page.props.customer?.discount?.coupon" class="ml-2 text-green-700">
                                <DollarAmount
                                  :amount="frequency === 'monthly' ?
                                    plan.monthly_price - Math.floor(plan.monthly_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 :
                                    (plan.annual_price/12) - Math.floor(plan.annual_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 / 12"
                                />
                              </span>
                            </span>
                  <span class="text-gray-500">/month</span>
                </div>
                <div v-if="frequency === 'annual'" class="text-gray-500 text-xs mt-2">
                  billed annually
                </div>
              </div>
              <ul role="list" class="mb-8 space-y-4 text-left">
                <li class="flex items-center space-x-3">
                  <CheckIcon class="size-5 text-green-500" />
                  <span><span class="font-semibold">{{ plan.storage_minutes }}</span> minutes of storage</span>
                </li>
                <li class="flex items-center space-x-3">
                  <CheckIcon class="size-5 text-green-500" />
                  <span>Invite unlimited contributors</span>
                </li>
                <li class="flex items-center space-x-3">
                  <CheckIcon class="size-5 text-green-500" />
                  <span>Rearrange clips</span>
                </li>
                <li class="flex items-center space-x-3">
                  <CheckIcon class="size-5 text-green-500" />
                  <span>Share with link or QR code</span>
                </li>
                <li class="flex items-center space-x-3">
                  <CheckIcon class="size-5 text-green-500" />
                  <span>Page view analytics</span>
                </li>
                <li class="flex items-center space-x-3">
                  <CheckIcon class="size-5 text-green-500" />
                  <span v-if="plan.playlists !== -1">Playlists: <span class="font-semibold">{{ plan.playlists }}</span></span>
                  <span v-else>Playlists: <span class="font-semibold">Unlimited</span></span>
                </li>
                <li v-if="!!plan.collaborators" class="flex items-center space-x-3">
                  <CheckIcon class="size-5 text-green-500" />
                  <span>Collaborators: <span class="font-semibold">{{ plan.collaborators }}</span></span>
                </li>
                <li v-if="!plan.ad_supported" class="flex items-center space-x-3">
                  <CheckIcon class="size-5 text-green-500" />
                  <span>Remove Advertising</span>
                </li>
              </ul>
              <div
                v-if="selectedPlan.id !== plan.id"
                class="btn-primary inline mt-auto cursor-pointer"
                @click="changePlan(plan.id)"
              >
                {{ plan.trial_days > 0 ? 'Start Free Trial' : 'Get Started' }}
              </div>
              <span v-else class="text-green-700 flex gap-2 items-center justify-center mt-auto whitespace-nowrap px-6 py-3 text-sm font-bold ">
                          <CheckIcon class="size-6" /> Selected
                        </span>
            </div>
          </template>
        </div>
      </template>
      <template v-if="$env.VITE_APP_ID==='rt' || $env.VITE_APP_ID==='esr'">
        <ul class="mb-5 text-lg font-medium text-gray-900 list-disc max-w-fit mx-auto">
          <template v-if="!!$page.props.auth.user.business">
            <li>Includes {{ selectedPlan.storage_minutes }} minutes of cloud storage</li>
            <li>Add unlimited sub-accounts and allocate storage as needed</li>
            <li>Access all client media in one easy-to-use system</li>
          </template>
          <template v-else>
            <li>
              Upload auditions, clips and reels
            </li>
            <li>
              Link media library with Agents and Managers
            </li>
          </template>
          <li>
            Create unlimited custom playlists that include analytics
          </li>
          <li>
            Easily share media with downloadable links
          </li>
          <li>
            Generate QR codes for headshots &amp; websites
          </li>
          <li>
            Access to priority customer support and more!
          </li>
        </ul>
        <p v-if="!$page.props.auth.user.business" class="max-w-prose mx-auto">
          Get {{ selectedPlan.trial_days }} days free and a coupon for 20% off reel editing!
        </p>
        <p v-else class="max-w-prose mx-auto">
          Your {{ selectedPlan.trial_days }}-day free trial includes an onboarding session and a dedicated media management specialist. Get started today!
        </p>
      </template>
    </div>
  </section>

  <div class="flex flex-col md:flex-row gap-5">
    <div class="w-full max-w-prose mx-auto">
      <div class="flex w-full flex-col mt-4 gap-5 rounded bg-white shadow p-5">
        <Promo />
        <Payment
          ref="paymentComponent"
          :client-secret="$page.props.setupIntent"
          @success="(setupIntent) => intentConfirmed(setupIntent)"
          @failure="(error) => handleError(error)"
        />
        <div v-if="errors" class="flex justify-between items-center">
          <div class="text-xs font-medium text-red-700 mr-12">
            {{ errors.message }}
          </div>
        </div>
        <div v-if="$page.props.errors" class="flex justify-between items-center">
          <div v-for="(error, index) in $page.props.errors?.pay" :key="index" class="text-xs font-medium text-red-700 mr-12">
            {{ error }}
          </div>
        </div>
        <LoadingButton
          :loading="cardProcessing || form.processing"
          class="btn-primary ml-auto"
          type="submit"
          @click="submit"
        >
          Subscribe
        </LoadingButton>
        <div class="text-xs">
          {{ selectedPlan.name }} is a recurring subscription service offered by {{ $env.VITE_APP_NAME }}.
          <template v-if="selectedPlan.trial_days > 0">
            Following your {{ selectedPlan.trial_days }}-day free trial period,
            your card will be automatically charged
            <span :class="{'line-through': !!$page.props.customer?.discount?.coupon}">
                        <DollarAmount :amount="frequency === 'monthly' ? selectedPlan.monthly_price : selectedPlan.annual_price" />
                      </span>
            <span v-if="!!$page.props.customer?.discount?.coupon" class="ml-2 text-green-700">
                        <DollarAmount
                          :amount="frequency === 'monthly' ?
                            selectedPlan.monthly_price - Math.floor(selectedPlan.monthly_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 :
                            (selectedPlan.annual_price/12) - Math.floor(selectedPlan.annual_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100"
                        />
                      </span>
            each {{ frequency === 'monthly' ? 'month' : 'year' }}
            <span v-if="!!$page.props.customer?.discount?.coupon">
                        until <DateString :date="$page.props.customer?.discount?.end * 1000" /> when you will revert to the non-promotional price at
                        <DollarAmount :amount="frequency === 'monthly' ? selectedPlan.monthly_price : selectedPlan.annual_price" />
                        each {{ frequency === 'monthly' ? 'month' : 'year' }}
                      </span>
            according to your selected plan.
          </template>
          <template v-else>
            Your card will be charged
            <span :class="{'line-through': !!$page.props.customer?.discount?.coupon}"><DollarAmount :amount="selectedPlan.monthly_price" /></span>
            <span v-if="!!$page.props.customer?.discount?.coupon" class="ml-2 text-green-700">
                        <DollarAmount
                          :amount="frequency === 'monthly' ?
                            selectedPlan.monthly_price - Math.floor(selectedPlan.monthly_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 :
                            (selectedPlan.annual_price/12) - Math.floor(selectedPlan.annual_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100"
                        />
                        today, and automatically each {{ frequency === 'monthly' ? 'month' : 'year' }}
                      </span>
            according to your selected plan.
          </template>
          You can cancel or change your plan anytime in your account settings. For more details, see our
          <a class="text-secondary hover:text-secondary-600" :href="`${ $env.VITE_BROCHUREWARE_URL }/terms-and-conditions`" target="_blank">Terms of Service</a> and
          <a class="text-secondary hover:text-secondary-600" :href="`${ $env.VITE_BROCHUREWARE_URL }/privacy-policy`" target="_blank">Privacy Policy</a>.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useForm, usePage } from '@inertiajs/vue3';
import * as $routes from '@/routes';
import { CheckIcon } from '@heroicons/vue/24/outline';
import Toggle from '@vueform/toggle'
import { ref } from 'vue';
import DollarAmount from '../../Shared/DollarAmount.vue';
import Payment from '../../Shared/SetupIntent.vue';
import LoadingButton from '../../Shared/LoadingButton.vue';
import Promo from './Promo.vue';
import DateString from '../../Shared/DateString.vue';

const paymentComponent = ref()
const cardProcessing = ref(false)
const errors = ref(null)
const frequency = ref('annual')
const selectedPlan = ref(usePage().props.currentPlan)
const planPriceId = ref(selectedPlan.value.annual_stripe_price_id)

const form = useForm({
  plan: planPriceId.value,
  pm_id: null,
  stripe_pm_id: null
})

const intentConfirmed = (setupIntent) => {
  form.stripe_pm_id = setupIntent.payment_method
  form.post($routes.subscriptions(),
    {
      preserveScroll: true,
      onError: () => {
        cardProcessing.value = false;
      }
    })
}

const submit = () => {
  cardProcessing.value=true
  paymentComponent.value.createPaymentMethod()
}
const changePlan = (planId) => {
  selectedPlan.value = usePage().props.plans.find(p=>p.id === planId)
  updatePlanPriceId()
}

const changeFrequency = (freq) => {
  frequency.value = freq
  updatePlanPriceId()
}

const updatePlanPriceId = () => {
  form.plan = frequency.value === 'monthly' ? selectedPlan.value.monthly_stripe_price_id : selectedPlan.value.annual_stripe_price_id
}

const handleError = (error) => {
  cardProcessing.value = false
  errors.value = error
}
</script>
