<template>
  <div class="flex justify-between text-sm items-center" :class="{'bg-green-100': progress === 100}">
    <img v-if="file.type.indexOf('image') !== -1" :src="previewUrl(file)" :alt="file.name" class="size-12">
    <video v-else-if="file.type.indexOf('video') !== -1" :src="previewUrl(file)" class="size-12 bg-black" />
    <div class="flex gap-2 items-center">
      <TextInput
        v-model="form.upload.name"
        :errors="form.errors.name"
        class="w-full"
      />
    </div>
    <div v-show="!processing" class="flex gap-2 items-center max-w-fit">
      <div class="text-xs hidden sm:block">
        (<FilesizeString :size="file.size" />)
      </div>
      <XCircleIcon class="size-5 text-red-700 cursor-pointer" @click="emit('destroy', file)" />
    </div>
    <div v-if="processing && progress < 100 && progress > 0" class="flex gap-2 w-full">
      <div class="text-xs">
        Uploading
      </div>
      <div class="w-full bg-gray-200 rounded-full h-4 max-w-56 ml-auto">
        <div class="bg-primary-600 text-xs font-medium text-white text-center p-0.5 leading-none rounded-full h-4" :style="`width: ${progress}%`">
          {{ Math.round(progress) }}%
        </div>
      </div>
    </div>
    <div v-if="form.processing" class="flex gap-1 text-xs">
      Saving <div class="btn-spinner border-t-green-700 border-b-green-700 border-r-green-700" />
    </div>
    <CheckIcon v-if="progress === 100 && !form.processing" class="size-4 text-green-700" />
  </div>
</template>

<script setup>
import { DirectUpload } from '@rails/activestorage'
import FilesizeString from '../../Shared/FilesizeString.vue';
import { XCircleIcon, PencilSquareIcon, CheckCircleIcon, CheckIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import TextInput from '../../Shared/TextInput.vue';
import { useForm } from '@inertiajs/vue3';

const props = defineProps({
  file: {
    type: File,
    required: true
  },
  route: {
    type: String,
    required: true
  },
  formData: {
    type: Object,
    required:true
  }
})
const emit = defineEmits(['success', 'destroy']);

const processing = ref(false)
const editing = ref(false)
const progress = ref(0)

const updateProgress = (event) => {
  progress.value = (event.loaded / event.total) * 100
}

class Upload {
  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => updateProgress(event));
  }
}
const form = useForm({
  ...props.formData,
  upload: {
    name: props.file.name.substring(0, props.file.name.lastIndexOf('.')) || props.file.name,
    file: props.file,
  }
})

const submit = () => {
  processing.value = true
  const upload = new DirectUpload(props.file, '/rails/active_storage/direct_uploads', new Upload);
  upload.create((error, blob) => {
    if (error) {
      alert(error)
    } else {
      form.upload.file = blob
      form.signed_blob_id= blob.signed_id
      form.post(props.route, {
        preserveState: false,
        onSuccess: () => {
          form.reset('upload');
          emit('success');
        },
      });
    }
  })
}
defineExpose({ submit })

const previewUrl = (file) => {
  return URL.createObjectURL(file)
}
</script>
