<template>
  <div class="border-b border-gray-300 mb-4">
    <ul class="flex flex-wrap -mb-px text-xs md:text-sm font-medium text-center text-secondary-700">
      <li class="me-2">
        <Link
          :href="!config.pageAlias ? $routes.page(page.hashid) : $routes.tribute(page.hashid)"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            $routes.page(page.hashid) === $page.url || $routes.tribute(page.hashid) === $page.url ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="$routes.page(page.hashid) === $page.url || $routes.tribute(page.hashid) === $page.url? 'page' : null"
        >
          Videos
        </Link>
      </li>
      <li class="me-2">
        <Link
          :href="!config.pageAlias ? $routes.page_gallery(page.hashid) : $routes.tribute_gallery(page.hashid)"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            $routes.page_gallery(page.hashid) === $page.url || $routes.tribute_gallery(page.hashid) === $page.url ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="$routes.page_gallery(page.hashid) === $page.url || $routes.tribute_gallery(page.hashid) === $page.url ? 'page' : null"
        >
          Images
        </Link>
      </li>
      <li v-if="canManage && !!config.enablePlaylistContributors" class="me-2">
        <Link
          :href="!config.pageAlias ? $routes.page_collaboration(page.hashid) : $routes.tribute_collaboration(page.hashid)"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            $routes.page_collaboration(page.hashid) === $page.url || $routes.tribute_collaboration(page.hashid) === $page.url ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="$routes.page_collaboration(page.hashid) === $page.url || $routes.tribute_collaboration(page.hashid) === $page.url ? 'page' : null"
        >
          Contributors
        </Link>
      </li>
      <li v-if="canManage" class="me-2">
        <Link
          :href="!config.pageAlias ? $routes.edit_page(page.hashid) : $routes.edit_tribute(page.hashid)"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            $routes.edit_page(page.hashid) === $page.url || $routes.edit_tribute(page.hashid) === $page.url ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="$routes.edit_page(page.hashid) === $page.url || $routes.edit_tribute(page.hashid) === $page.url  ? 'page' : null"
        >
          Settings
        </Link>
      </li>
      <li class="ml-auto">
        <a
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2 border-transparent text-white bg-tertiary hover:text-tertiary-50 hover:bg-tertiary-400 hover:border-tertiary-300 rounded-t"
          :href="!config.pageAlias ? $routes.page_view(page.hashid) : $routes.tribute_view(page.hashid)"
          target="_blank"
        >
          <EyeIcon class="size-4 md:size-5 mr-2" />
          Preview
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { Link, usePage } from '@inertiajs/vue3';
import { EyeIcon } from '@heroicons/vue/24/outline';
import { config } from '@/siteConfig'

const props = defineProps(['page'])

const canManage = usePage().props.auth.user.hashid === props.page.user.hashid || props.page.permissions?.manage
</script>
